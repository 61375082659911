import React from "react";
import {Link} from "gatsby";

import Body from "components/layout/Body/Body";
import SEO from "components/seo";

const Projects = () => (
	<>
		<SEO title="Portfolio - Kevin Mamaqi Kapllani" />
		<h1>Projects</h1>
		<p>About me.</p>
		<Link to="/projects/euroviajar/">Euroviajar</Link>
		<Link to="/">Go back to the homepage</Link>
	</>
);
Projects.Layout = Body;
export default Projects;
